<template>
  <v-container class="fill-height">
    <div class="d-flex flex-column fill-height fill-width">
      <!-- <crm-main-toolbar class="mb-2" /> -->
      <!-- <crm-secondary-toolbar /> -->
      <router-view class="fade-motion fill-height"></router-view>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */

// import CrmMainToolbar from "./layout/CrmMainToolbar.vue";
// import CrmSecondaryToolbar from "./layout/CrmSecondaryToolbar.vue";

export default {
  // components: { CrmMainToolbar },
  name: "crm-search-global",
  mounted() {
    this.$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      this.$parent.$parent.$parent.drawer = true;
    }
  },
};
</script>
